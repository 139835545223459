import {createApp} from 'vue';
import App from './App.vue';
import Emitter from 'tiny-emitter';
import { createPinia } from 'pinia'
import piniaPluginPesistedstate from 'pinia-plugin-persistedstate'
import router from './router'



const pinia = createPinia()
pinia.use(piniaPluginPesistedstate)
const app = createApp(App);


app.use(pinia);
app.use(router);

app.mount('#app')


app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();

