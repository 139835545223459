<template>
  <div class="menu" :class="{ 'small-menu': smallMenu }">
    <span>Hi! {{ this.uStore.userProfile.name }}</span>
    <MenuItem
        v-for="(item, index) in menuTree"
        :key="index"
        :data="item.children"
        :label="item.label"
        :icon="item.icon"
        :input="item.input"
        :depth="0"
        :smallMenu="smallMenu"
    />
    <i @click="smallMenu = !smallMenu" class="material-icons"> last_page </i>
  </div>
</template>

<script>
import MenuItem from "./subcomponents/MenuItem.vue";
import {userStore} from "@/stores/user";

export default {
  name: "recursive-menu",
  setup() {
    const uStore = userStore()

    return {
      uStore
    }
  },
  data: () => ({
    smallMenu: false,
    menuTree: [
      {
        label: "Filters",
        icon: "tune",
      },
      {
        label: "Assistant Type",
        icon: "dashboard_customize",
        children: [
          {
            label: "Web Discovery",
            icon: "brightness_1",
          },
          {
            label: "Chat Discovery",
            icon: "circle",
          },
          {
            label: "Visual Discovery",
            icon: "circle",
          },
        ],
      },
      {
        label: "Industry",
        icon: "factory",
        children: [
          {
            label: "Consumer Electronics",
            icon: "circle",
          },
          {
            label: "Home and Living",
            icon: "circle",
          },
          {
            label: "Industrials",
            icon: "circle",
          },
          {
            label: "Sports and Outdoor",
            icon: "circle",
          },
          {
            label: "Other",
            icon: "circle",
          },
        ],
      },
      {
        label: "Status",
        icon: "podcasts",
        children: [
          {
            label: "Active",
            icon: "circle",
          },
          {
            label: "Inactive",
            icon: "circle",
          },
        ],
      },
      {
        label: "Account",
        icon: "account_circle",
        children: [
          {
            input: "Search",
            icon: "search",
          },
        ],
      },
      {
        label: "Language",
        icon: "language",
        children: [
          {
            label: "English",
            icon: "circle",
          },
        ],
      },
      {
        label: "Country & Region",
        icon: "location_on",
        children: [
          {
            label: "UK",
            icon: "circle",
          },
          {
            label: "USA",
            icon: "circle",
          },
          {
            label: "DE",
            icon: "circle",
          },
        ],
      },
      {
        label: "Date Range",
        icon: "date_range",
        children: [
          {
            label: "Create Date",
            icon: "circle",
          },
          {
            label: "Last Modified Date",
            icon: "circle",
          },
        ],
      },
    ],
  }),
  components: {
    MenuItem,
  },
};
</script>

<style lang="css" scoped>
.menu {
  border-right: 1px solid #f5f6f8;
  transition: all 0.3s ease;
  overflow: auto;
  background-color: #f5f6f8;
  flex: 1;
}

.menu i {
  position: fixed;
  left: 20px;
  font-size: 20px;
  bottom: 20px;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: rotate(180deg);
}

.menu.small-menu {
  overflow: inherit;
  width: 60px;
  padding-top: 50px;
}

.menu.small-menu i {
  left: 20px;
  transform: rotate(360deg);
}
</style>
