<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@font-face {
  font-family: Campton;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url(https://ssofront.zoovu.com/v1/fonts/1a93da01806c218a8cc757bd55e5fad9.woff2)
      format("woff2"),
    url(https://ssofront.zoovu.com/v1/fonts/f820d2af9adb9705cd4cbfecb17611ed.woff)
      format("woff"),
    url(https://ssofront.zoovu.com/v1/fonts/243169c6773e2d0d45ad7964aad92322.otf)
      format("opentype"),
    url(https://ssofront.zoovu.com/v1/fonts/5cce0d5df4fabba091fc1c699df1bb33.ttf)
      format("truetype"),
    url(https://ssofront.zoovu.com/v1/fonts/1a5370b0647fec0f8e9cbc81f2e37e52.eot)
      format("embedded-opentype");
}

@font-face {
  font-family: Campton;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(https://ssofront.zoovu.com/v1/fonts/dd7bc82846c4db9618e67f89b6d26994.woff2)
      format("woff2"),
    url(https://ssofront.zoovu.com/v1/fonts/c6dad2d6d99d295b93b9dcd566c473ea.woff)
      format("woff"),
    url(https://ssofront.zoovu.com/v1/fonts/43624a1cc7aedaef9147282fa9b59e81.otf)
      format("opentype"),
    url(https://ssofront.zoovu.com/v1/fonts/d65891e51be6b68a07353ffde0714424.ttf)
      format("truetype"),
    url(https://ssofront.zoovu.com/v1/fonts/564265b02785b5a4648fa8885ba109e6.eot)
      format("embedded-opentype");
}

@font-face {
  font-family: Campton;
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: url(https://ssofront.zoovu.com/v1/fonts/48789ff905a14b365a60410000297065.woff2)
      format("woff2"),
    url(https://ssofront.zoovu.com/v1/fonts/eb19dbb1e30463a56f06662345ba9289.woff)
      format("woff"),
    url(https://ssofront.zoovu.com/v1/fonts/c31bf831338d9150b2ac50f754fcd4fd.otf)
      format("opentype"),
    url(https://ssofront.zoovu.com/v1/fonts/51f0f7a5782919010c9ef557c8172d10.ttf)
      format("truetype"),
    url(https://ssofront.zoovu.com/v1/fonts/4d5c398c58477be55ce087e3b6b95e01.eot)
      format("embedded-opentype");
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 1rem;
  font-family: Campton, Helvetica, Arial, sans-serif;
  line-height: 1.4;
  overflow-x: hidden;
}

body {
  margin: 0;
}

body,
html,
#app {
  min-height: 100%;
  height: 100%;
  display: flex;
  width: 100vw;
}
</style>
