import {PublicClientApplication} from "@azure/msal-browser";

   export const signIn = async (context) => {

        context.$msalInstance = new PublicClientApplication(context.aStore.msalConfig,)
        await context.$msalInstance
            .loginPopup({})
            .then((ev) => {
                const myAccounts = context.$msalInstance.getAllAccounts();
                context.account = myAccounts[0];
                context.uStore.setUserProfile(context.account);
                context.$emitter.emit(context.cStore.login, context.account);
                context.aStore.setAccessToken(ev.idToken);
                context.$router.push('/gallery');
            })
            .catch(error => {
                console.error(`error during authentication: ${error}`);
            });
    }

  //
  // export const  signOut = async (context) => {
  //       await context.$msalInstance
  //           .logoutPopup({})
  //           .then(() => {
  //               context.$emitter.emit('logout', 'logging out');
  //               context.$store.commit('removeAccessToken');
  //               context.$router.push('/');
  //           })
  //           .catch(error => {
  //               console.error(error);
  //           });
  //       await context.$store.dispatch('accessToken', undefined)
  //   }


