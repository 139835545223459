<template>
 <h1>We couldn't find that path in our site, redirecting you to the home page in {{secondsToRedirect}}.</h1>
</template>

<script>


export default {
  name: 'error',
  data() {
    return {
      secondsToRedirect: 7
    }
  },
  mounted() {
    let redirectInterval = window.setInterval(()=>{
      this.$data.secondsToRedirect--
      if(this.$data.secondsToRedirect === 0){
        window.location.href = '/'
        window.clearInterval(redirectInterval)
      }
    },1000)
  }
}


</script>

