<template>
  <div id="featuredCarousel">

    <Card v-for="index in Object.keys(dStore.featured)" :key="'fCard'+ index" :index="index">
    </Card>
  </div>
</template>

<script>
import Card from "@/components/subcomponents/Card";
import {demosStore} from "@/stores/demos"
export default {
  name: "FeaturedCarousel",
  setup(){
    const dStore = demosStore()
    return {
      dStore
    }
  },
  components: {Card},



}
</script>

<style scoped>
#featuredCarousel {
display: flex;
  flex: 3;
  border: 1px solid green;
  height: min-content;
}
</style>