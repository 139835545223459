import {defineStore} from 'pinia'


export const authStore = defineStore('auth', {
    state: () => {
        return {
            msalConfig: {
                auth: {
                    clientId: '480cacdc-eb94-4826-b75d-7b99c5a9d3b0',
                    authority:
                        'https://login.microsoftonline.com/746eb24a-168f-4d39-9bd2-5361b5417de6',
                    redirectUri: window.location.origin + '/gallery'
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
            },
            accessToken: ''
        };
    },
    persist: true,
    actions: {
        setAccessToken(token) {
            this.accessToken = token;
        },
        removeAccessToken() {
            window.localStorage.clear();
            this.accessToken = '';
        }

    }
});