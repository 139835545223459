<template>


  <iframe id="liveFrame" :src="liveUrls[demoId]" allow="geolocation;"></iframe>
</template>

<script>



export default {
  name: 'viewer',
  data(){
    return {
      liveUrls:{
        finserv: "https://victorious-sand-0bde5e603.1.azurestaticapps.net/",
      },
      demoId:this.$route.query.id
    }
  }
};
</script>

<style>
#liveFrame {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>