import {defineStore} from 'pinia'


export const demosStore = defineStore('demos', {
    state: () => {
        return {
            featured: {},
            general: {}
        }
    },
    persist: true,
    actions: {
        setFeaturedCards(cards) {
            this.featured = cards;
        },
        setGeneralCards(cards) {
            this.general = cards;
        }
    }
});