import {defineStore} from 'pinia'


export const userStore = defineStore('user', {
    state: () => {
        return {
            userId: '',
            appProps: {
                accessLevel: 0,
                likedDemos: []
            },
            userProfile: {}

        }
    },
    persist: true,
    actions: {
        setUserProps(props) {
            this.userId = props;
        },
        setUserProfile(profile) {
            this.userProfile = profile;
            this.userId = profile.homeAccountId;
        }
    }
});