<template>
  <Menu/>
  <FeaturedCarousel></FeaturedCarousel>
</template>

<script>
import {authStore} from '@/stores/auth'
import {demosStore} from "@/stores/demos";
import Menu from "../components/Menu.vue";
import FeaturedCarousel from "@/components/FeaturedCarousel";
import Mongo from "@/integrations/Mongo";


export default {
  name: "gallery",
  components: {Menu, FeaturedCarousel},
  setup() {
    const aStore = authStore()
    const dStore = demosStore()
    dStore.setFeaturedCards(Mongo.getFeaturedDemoData());
    dStore.setGeneralCards(Mongo.getGeneralDemoData());
    return {
      aStore,
      dStore
    }
  },
  data() {
    return {};
  }
};
</script>
