const getUserData = (userId) => {
    console.log('Got data for user ' + userId)
    return {
        accessLevel: 'editor',
        likedDemos: ['00000', '00001']
    }
}

const getFeaturedDemoData = () => {

    return [
        {
            cardId: "00000",
            headerSrc: "https://customers.zoovu.com/media/img/welcome.png",
            customerName: "Zoovu",
            customerLogoSrc: "https://customers.zoovu.com/media/img/zoovu_logo.jpg",
            customerIndustry: "Software",
            demoModificationDate: "12/06/2022",
            demoCreationDate: "11/06/2022",
            demoDescription: "This is a demo, who would have known? A demo in a demos site!? Unbelievable!",
            cardLikeCount: 20
        }
    ]
}

const getGeneralDemoData = () => {
    return []
}



export default {getUserData, getFeaturedDemoData, getGeneralDemoData};