import {defineStore} from 'pinia'


export const constantsStore = defineStore('constants', {
    state: () => {
        return {
            login: "Log In"
        }
    },
    persist: true,
});