<template>

  Profile View
</template>

<script>


export default {
  name: 'profile',

};
</script>