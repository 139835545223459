import {createRouter, createWebHistory} from 'vue-router';


import home from "@/views/Home";
import gallery from '@/views/Gallery';
import viewer from "@/views/Viewer";
import editor from '@/views/Editor';
import profile from '@/views/Profile';
import error from '@/views/Error'

import {authStore} from "@/stores/auth";


const routes = [
    {path: '/', component: home, name: 'home'},
    {path: '/gallery', component: gallery, name: 'gallery'},
    {path: '/viewer', component: viewer, name: 'viewer'},
    {path: '/editor', component: editor, name: 'editor'},
    {path: '/profile', component: profile, name: 'profile'},
    {path: '/error', component: error, name: 'error'}
]


const router = createRouter({
    history: createWebHistory(),
    routes: routes
})
router.beforeEach((to, from, next) => {
    const store = authStore()
    let isAuthenticated = !!store.$state.accessToken;
    let isRoute = !!to.name;

    if(!isRoute){
        next({name:'error'})
    }
else if (isAuthenticated && to.name === 'home') {
        next({name: 'gallery'})
    } else if (isAuthenticated) {
        next()
    } else if (to.name === 'home' || to.name === 'viewer') {
        next()
    } else {
        next({name: 'home'})
    }
})

export default router