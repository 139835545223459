<template>
  Editor View
  <form id="newDemo">
    <input ref="file" v-on:change="handleUpload" type="file">
  </form>

</template>

<script>
import axios from 'axios'

export default {
  name: 'editor',
  data() {
    return {}
  },
  methods: {
    handleUpload(ev) {
      console.log(ev);
      let selectedFile = (ev.target.files || ev.dataTransfer.files)[0]
      const formData = new FormData();
      formData.append("file", selectedFile);  // appending file

      // sending file to the backend
      axios
          .post("/api/upload", formData)
          .then(res => {
            console.log(res);
          })
          .catch(err => {
            console.log(err);
          });
    }
  }
}


</script>

