<template>
  <div class="menu-item" :class="{ opened: expanded }">
    <div
      class="label"
      @click="toggleMenu()"
      :style="{ paddingLeft: depth * 20 + 20 + 'px' }"
    >
      <div class="left">
        <i v-if="icon" class="material-icons-outlined">{{ icon }}</i>
        <span v-if="showLabel">{{ label }}</span>
        <input
          v-if="input"
          type="text"
          class="account-search"
          placeholder="Search for your account...."
        />
      </div>
      <div v-if="data" class="right">
        <i class="expand material-icons" :class="{ opened: expanded }"
          >expand_more</i
        >
      </div>
    </div>
    <div
      v-show="showChildren"
      :class="{ 'small-menu': smallMenu }"
      class="items-container"
      :style="{ height: containerHeight }"
      ref="container"
    >
      <menu-item
        :class="{ opened: showChildren }"
        v-for="(item, index) in data"
        :key="index"
        :data="item.children"
        :label="item.label"
        :icon="item.icon"
        :input="item.input"
        :depth="depth + 1"
        :smallMenu="smallMenu"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "menu-item",
  data: () => ({
    showChildren: false,
    expanded: false,
    containerHeight: 0,
  }),
  props: {
    data: {
      type: Array,
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    input: {
      type: String,
    },
    depth: {
      type: Number,
    },
    smallMenu: {
      type: Boolean,
    },
  },
  computed: {
    showLabel() {
      return this.smallMenu ? this.depth > 0 : true;
    },
  },
  methods: {
    toggleMenu() {
      this.expanded = !this.expanded;
      if (!this.showChildren) {
        this.showChildren = true;
        this.$nextTick(() => {
          this.containerHeight = this.$refs["container"].scrollHeight + "px";
          setTimeout(() => {
            this.containerHeight = "fit-content";
            if (navigator.userAgent.indexOf("Firefox") != -1)
              this.containerHeight = "-moz-max-content";
            this.$refs["container"].style.overflow = "visible";
          }, 300);
        });
      } else {
        this.containerHeight = this.$refs["container"].scrollHeight + "px";
        this.$refs["container"].style.overflow = "hidden";
        setTimeout(() => {
          this.containerHeight = 0 + "px";
        }, 10);
        setTimeout(() => {
          this.showChildren = false;
        }, 300);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.menu-item {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #dadfe9;
}
.menu-item .label {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
  user-select: none;
  height: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 14px;
  color: #414344;
  transition: all 0.3s ease;
}

.menu-item .label > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu-item .label > div i {
  font-size: 20px;
  color: #414344;
  transition: all 0.3s ease;
}

.menu-item .label > div i.expand {
  font-size: 16px;
}
.menu-item .label > div i.opened {
  transform: rotate(180deg);
}

.menu-item .label.small-item {
  width: fit-content;
}

.menu-item .label:hover {
  background: #deedff;
  cursor: pointer;
  -webkit-transform: translateX(3px);
  -moz-transform: translateX(3px);
  -ms-transform: translateX(3px);
  -o-transform: translateX(3px);
  transform: translateX(3px);
}

.items-container {
  width: 100%;
  left: calc(100% + 6px);
  transition: height 0.3s ease;
  overflow: hidden;
}
.items-container.small-menu {
  width: fit-content;
  position: absolute;
  background: #fff;
  box-shadow: 0 0 10px #ebebeb;
  top: 0;
}
.items-container.small-menu .label {
  width: 100% !important;
  padding-left: 20px !important;
}
.items-container .left i {
  font-size: 5px !important;
  background: #414344;
  border-radius: 50px;
  margin-top: -2px;
}
.menu-item:nth-of-type(5) div.items-container > div > div.label > div > i {
  font-size: 20px !important;
  background: transparent;
}
.menu-item:nth-of-type(5) .items-container > div > div.label > div > span {
  display: none;
}
.menu-item:nth-of-type(5) .items-container > div > div.label {
  padding-left: 20px !important;
}
.menu-item:nth-of-type(5) .items-container > div > div.label .account-search {
  display: block;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
