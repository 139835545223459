<template>
  <div class="cardWrapper" :id="'card'+cardProps.cardId" :ref="cardProps.cardId">
 
    <div class="cardHeaderWrapper">
      <img :src="this.cardProps.headerSrc" class="cardHeaderImg" :alt="cardProps.customerName"/>
      <img :src="this.cardProps.customerLogoSrc" class="cardHeaderLogo" :alt="cardProps.customerName"/>
      <h1 class="cardHeaderTitle"></h1>
    </div>
    <div class="cardBodyWrapper">
      <div class="cardIndustryTitle">{{ cardProps.customerIndustry }}</div>
      <div class="cardDates">Last modified:{{ cardProps.demoModificationDate }}</div>
      <div class="cardDates">Date created:{{ cardProps.demoCreationDate }}</div>
      <div class="cardDescription">{{ cardProps.demoDescription }}</div>
      <div class="cardLikeIcon cardLikeActive">&#9825;</div>
      <div class="cardLikeCount">{{ cardProps.cardLikeCount }} people have added this to their favourites.</div>
    </div>
    <div class="cardFooterWrapper">
      <button class="demoViewBtn">Launch</button>
      <button class="demoInfoBtn">Details</button>
    </div>
  </div>
</template>

<script>
import {demosStore} from "@/stores/demos";


export default {
  name: "Card",
  setup(){
    const dStoreFeatured = demosStore().featured;
    return {
      dStoreFeatured
    }
  },
  props:['index'],
  data() {
    return {
      cardProps: this.dStoreFeatured[this.$props.index],
      headerSrc: '',
      customerLogoSrc: ''
    }
  },
  methods: {
    handleViewClick(){
      this.$refs[this.cardProps.cardId].style.border = "2px solid red"
    },
    handleInfoClick(){
      this.$refs[this.cardProps.cardId].style.border = "2px solid blue"
    }
  }
}
</script>

<style scoped>
.cardWrapper {
  border: 1px solid red;
}
</style>