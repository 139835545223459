<template>
  <div id="loginContainer">
    <div id="loginBox" class="fadeIn">
      <img alt="Zoovu Banner" class="banner-img" src="../assets/scale.webp"/>
      <img alt="Zoovu Logo"
           class="logo"
           src="../assets/welcome.png"
      />
      <p class="sub-headline">Click below to log in using your Zoovu account</p>
      <button v-if="!aStore.account" @click="this.signIn(this)" class="navbarButtons">
        Log In
      </button>
    </div>
  </div>
</template>
<script>
import {authStore} from "@/stores/auth";

import {constantsStore} from "@/stores/constants";
import {userStore} from "@/stores/user";
import {signIn} from "@/integrations/Aad";

export default {
  name: "Home",
  setup() {
    const aStore = authStore()
    const cStore = constantsStore()
    const uStore = userStore()
    return {
      aStore,
      cStore,
      uStore
    }
  }, data() {
    return {
      account: this.aStore.accessToken
    };
  },
  methods: {
    signIn,
  }
};
</script>
<style scoped>
#loginContainer {
  min-height: 100%;
  height: 100%;
  margin: 0;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/pioneers-background-login.webp);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

#loginContainer #loginBox {
  background-color: #fff;
  padding: 35px;
  margin: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: rgb(0 0 0 / 25%) 4px 4px 5px;
  -moz-box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 5px;
  box-shadow: rgb(0 0 0 / 25%) 4px 4px 5px;
  border: solid 1px #dcdcdc;
  text-align: center;
  display: flex;
  flex-direction: column;
}

div#loginContainer::before {
  content: "";
  background: url(../assets/zoovu_logo.jpg) no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 9;
  display: block;
  width: 170px;
  height: 170px;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 1s;
}

#loginContainer #loginBox .banner-img {
  max-width: 350px;
  margin: 0 auto 16px;
}
#loginContainer #loginBox .navbarButtons {
  min-width: 225px;
  max-width: 225px;
  margin: 5px auto 0;
  padding: 8px 22px;
  font-size: 0.9375rem;
  color: #fff;
  background-color: #009385;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
  0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Campton, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.75;
  border-radius: 5px;
  text-transform: none;
  cursor: pointer;
  border: none;
}

#loginContainer #loginBox .navbarButtons:hover {
  border-color: #00665d;
  background-color: #00665d;
}

#loginContainer #loginBox .sub-headline {
  font-size: 13.5px;
  font-weight: 100;
}

/* Responsive Design */

/* Low Res Desktops */
@media only screen and (max-width: 1367px) {
  div#loginContainer::before {
    transform: scale(0.6);
    top: -25px;
  }
  #loginContainer #loginBox .banner-img {
    max-width: 300px;
  }
}

/* Tablets */

@media only screen and (min-device-width: 835px) and (max-device-width: 1024px) {
  div#loginContainer::before {
    top: 50px;
    transform: scale(1);
  }
  div#loginContainer {
    background-image: url(../assets/Login_Page_Tablet_Portrait.webp);
  }
  div#loginContainer #loginBox {
    margin-top: -300px;
  }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1366px) and (orientation: landscape) {
  div#loginContainer::before {
    top: 0;
    transform: scale(1);
  }
  div#loginContainer {
    background-image: url(../assets/Login_Page_Tablet_Landscape.webp);
  }
}

@media only screen and (min-device-width: 500px) and (max-device-width: 834px) {
  div#loginContainer::before {
    top: 50px;
    transform: scale(1);
  }
  div#loginContainer {
    background-image: url(../assets/Login_Page_Tablet_Portrait.webp);
  }
  div#loginContainer #loginBox {
    margin-top: 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  div#loginContainer #loginBox {
    margin-top: 0;
  }
  div#loginContainer::before {
    top: -35px;
    transform: scale(0.7);
  }
  div#loginContainer {
    background-image: url(../assets/Login_Page_Tablet_Landscape.webp);
  }
}

/* Mobile Portrait */

@media only screen and (max-device-width: 500px) and (orientation: portrait) {
  div#loginContainer {
    background-image: url(../assets/Login_Page_Mobile_Landscape.webp);
  }
  div#loginContainer #loginBox {
    width: 100%;
    padding: 30px 15px;
  }
  #loginContainer #loginBox .banner-img {
    max-width: 250px;
  }
}

@media only screen and (max-device-width: 896px) and (orientation: landscape) {
  #loginContainer #loginBox .banner-img {
    display: none;
  }
  div#loginContainer::before {
    top: -45px;
    transform: scale(0.5);
  }
  div#loginContainer #loginBox {
    padding: 25px;
  }
}

/* Animation definitions */
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 1s;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>